import React from 'react'
import { Fade } from "react-reveal"
//import pic01 from '../assets/images/pic01.jpg'
//import { Link as ScrollLink } from 'react-scroll'
//import Lottie from 'react-lottie-player'
//import LottieJson from '../assets/lotties/5167-graph-icon.json'
//import Lottie1 from '../components/lottie1'
//import Recaptcha from 'react-recaptcha'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../components/customToast.css'
//import nodemailer from 'nodemailer'
import axios from 'axios'
//import passwordHash from 'password-hash'
import bcrypt from 'bcryptjs'

  // render on captcha load
  /*function handleCaptchaLoad(event) {
    console.log('handleCaptchaLoad')
  }*/

  // load on callback verify
  /*function verifyCallback(event) {
    console.log('verifyCallback-->', event)
  }
  */

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};

//const Contact = props => {
class Contact extends React.Component {

constructor(props){
  super(props);
  this.state = {
    name: '',
    email: '',
	phone: '',
	message: '',
    errors: {
      name: 'Το πεδίο Όνομα είναι απαραίτητο',
      email: 'Το πεδίο Email είναι απαραίτητο',
      phone: 'Το πεδίο Τηλέφωνο είναι απαραίτητο',
      }
  }
}

  /*handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
	//console.log(this.state);
  }  */

handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'name':
        errors.name =
           value.length < 3
            ? 'Το όνομά σας πρέπει να είναι πάνω από 3 χαρακτήρες.'
            : '';
        break;
      case 'email':
        errors.email =
           validEmailRegex.test(value)
            ? ''
            : 'Το email που βάλατε δεν είναι σωστό';
        break;
      case 'phone':
        errors.phone =
          value.length < 10
            ? 'Το τηλέφωνο που βάλατε δεν είναι σωστό'
            : '';
        break;
      default:
        break;
    }

    this.setState({errors, [name]: value});
  }

  resetForm = event => {
	  this.setState({
		name: '',
		email: '',
		phone: '',
		message: '',
		errors: {
		  name: 'Το πεδίο Όνομα είναι απαραίτητο',
		  email: 'Το πεδίο Email είναι απαραίτητο',
		  phone: 'Το πεδίο Τηλέφωνο είναι απαραίτητο',
		}
	  });

  }

  handleSubmit = event => {

   // Check if data is valid
	if(validateForm(this.state.errors)) {

	//this.setState({
    //  error: "Συμπληρώστε το όνομά σας",
    //}, console.log(this.state))
	event.preventDefault()

    //return if not valid
	//console.log(this.state)
	//  {  this.state.error ? toast.error(this.state.error) : null }
	let thankYou = 'Ευχαριστούμε ' + this.state.name + ', σύντομα θα επικοινωνήσουμε!'
	//toast.info('Σύντομα θα επικοινωνήσουμε!')

// Hash pass
const saltRounds = 10
const hashedPassword = bcrypt.hashSync(process.env.MAILER_PASS, saltRounds)
	// login
	axios({
    method: "POST",
	  url: process.env.MAILER_ENDPOINT + 'getmein',
	//  data: {username: process.env.MAILER_USER, password: passwordHash.generate(process.env.MAILER_PASS)} ,
    data: {username: process.env.MAILER_USER, password: hashedPassword},
    headers:  {
		'Accept' : 'application/json',
		'Content-Type': 'application/json'
	  } ,
        }).then((response)=>{
			//console.log(response)

		    let myHeaders = {
				'Authorization' : 'Bearer' + ' ' + response.data.token,
				'Accept' : 'application/json',
				'Content-Type': 'application/json'
			}
	// send mail
	axios({
      method: "POST",
	  url: process.env.MAILER_ENDPOINT + 'send',
      data: this.state ,
	  headers:  myHeaders ,
        }).then((response)=>{
		//	console.log(response)
		  if (response.status === 200){
			  toast.success(thankYou)
              this.resetForm()
          }else {
			  toast.error('Κάτι δεν πήγε καλά. Παρακαλούμε, ξαναπατήστε το Αποστολή')
			  return
          }
        })
})
    }
	else {
		event.preventDefault()
	//	console.log(this.state)
	    let myerrors = this.state.errors
	    Object.values(myerrors).forEach(val => val !=="" && toast.error(val))
	 // toast.error(this.state.errors.name)
		 return
	}
  }

 /*   getError = (fieldName) => {
    return this.state[fieldName+"Error"];
  }
  setError = (fieldName, error) => {
    var update = {};
    update[fieldName+"Error"] = error;
    this.setState(update);
	//toast.error(error);
	//    this.setState({
    //  [name]: value,
    //})
  }

    //validateForm = event => {
	validateForm()  {
    var hasErrors = false;

    if (this.state.name.length < 1){
//      this.setError("name", "Συμπληρώστε το όνομά σας");
//toast.error("Συμπληρώστε το όνομά σας");
	    this.setState({
      error: "Συμπληρώστε το όνομά σας",
    }, console.log(this.state))
      hasErrors = true;
    } else this.setError("name", null)

    /*if (this.state.email == '') {
      this.setError("email", "Συμπληρώστε το email σας");
      hasErrors = true;
    } else this.setError("email", null)

    if (this.state.email !== /^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/) {
      this.setError("email", "To email που βάλατε δεν είναι σωστό.");
      hasErrors = true;
    } else this.setError("email", null)


    if (this.state.phone.length < 1) {
      this.setError("phone", "Συμπληρώστε το τηλέφωνό σας.");
      hasErrors = true;
    } else this.setError("phone", null)
	*/

  /*  return !hasErrors;
  }
  */

/*const captcha = {
	marginTop: '15px',
}
const [startAnimation, setStartAnimation] = useState(false);
  useEffect(() => {
    window.onscroll = () => {
      setStartAnimation(true)
	 // props.isUserScrolled(true)
    }
	 return () => {
        // this code fires on unmount! Perfect for our use case!
		//setStartAnimation(false)
		//props.isUserScrolled(false)
    }
  }, [startAnimation]);*/
  //console.log(startAnimation)
  //console.log(process)
  render() {
	  const startAnimation = false;
	  const ContainerStyle = {
		  background: '#8bbddf',
	  }
	  /*const toastStyle = {
		  background: '#8bbddf',
	  }*/
return (
    <section id="contact-form" className="wrapper style3 special fade inactive">
	<ToastContainer
		position="bottom-right"
		autoClose={6000}
		hideProgressBar={false}
		newestOnTop={false}
		closeOnClick
		rtl={false}
		pauseOnFocusLoss
		draggable
		pauseOnHover
		//bodyClassName='toastStyle'
		style={ContainerStyle}
	/>
    <Fade duration={2500}>
      <div className="container">
        <header>
          <h2>Φόρμα Επικοινωνίας</h2>
          <p>Ας επικοινωνήσουμε. Σίγουρα θα σας λύσουμε όποια σχετική απορία.</p>
        </header>
		{/* <form  onSubmit={this.handleSubmit} action="https://getform.io/f/34b8b5e9-8dee-4b09-8a4e-1760fa2bd938" method="POST">*/}
		<form  onSubmit={this.handleSubmit} method="POST">
            <div className="row gtr-uniform gtr-50">
              <div className="col-6 col-12-xsmall">
			   {/* {this.state.errors.name.length > 0 &&
				<span className='error'>{this.state.errors.name}</span>}*/}
                <input type="text" name="name" id="name" placeholder="Όνομα"
				  value={this.state.name}
				  onChange={this.handleInputChange}
				/>
              </div>
              <div className="col-6 col-12-xsmall">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
				  value={this.state.email}
				  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-12>">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Τηλέφωνο"
				  value={this.state.phone}
				  onChange={this.handleInputChange}
                />
              </div>
              {/*<div className="col-12">
                <select name="category" id="category">
                  <option value="">- Κατηγορία -</option>
                  <option value="1">Manufacturing</option>
                  <option value="1">Shipping</option>
                  <option value="1">Administration</option>
                  <option value="1">Human Resources</option>
                </select>
              </div>
              <div className="col-4 col-12-medium">
                <input
                  type="radio"
                  id="priority-low"
                  name="priority"
                  defaultChecked
                />
                <label htmlFor="priority-low">Χαμηλή προτεραιότητα</label>
</div>
              <div className="col-4 col-12-medium">
                <input type="radio" id="priority-normal" name="priority" />
                <label htmlFor="priority-normal">Μεσαία προτεραιότητα</label>
              </div>
              <div className="col-4 col-12-medium">
                <input type="radio" id="priority-high" name="priority" />
                <label htmlFor="priority-high">Υψηλή προτεραιότητα</label>
              </div>
              <div className="col-6 col-12-medium">
                <input type="checkbox" id="copy" name="copy" />
                <label htmlFor="copy">Email me a copy of this message</label>
              </div>
              <div className="col-6 col-12-medium">
                <input type="checkbox" id="human" name="human" defaultChecked />
                <label htmlFor="human">Δεν είμαι ρομπότ..</label>
</div>*/}
              <div className="col-12">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Το μήνυμά σας"
                  rows="6"
				  value={this.state.message}
				  onChange={this.handleInputChange}
                ></textarea>
              </div>
              <div className="col-12">
                <ul className="actions">
                  <li>
                    <input
                      type="submit"
                      value="Αποστολή"
                      className="primary"
                    />
                  </li>
				  {/*  <li>
                    <input type="reset" value="Reset" />
                  </li>*/}
                </ul>
              </div>
            </div>
			{/*	{ startAnimation &&
			<React.Fragment>
				<div style={captcha}>
					{/*<script src={`https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&r=${Math.random()}`} async defer></script>*/}
						{/*	<script src={`https://www.google.com/recaptcha/api.js?onload=onloadCallback&r=${Math.random()}`} async defer></script>
				<div className="g-recaptcha" data-sitekey={`${process.env.GATSBY_RECAPTCHA_KEY_2}`}></div>
				</div>
			</React.Fragment>
} */}
{ startAnimation &&
	<React.Fragment>
	{/*	<div style={captcha}>
		<script src={`https://www.google.com/recaptcha/api.js?onload=onloadCallback&r=${Math.random()}`} async defer></script>
			<Recaptcha
			sitekey={`${process.env.GATSBY_RECAPTCHA_KEY_2}`}
			verifyCallback={verifyCallback}
			onloadCallback={handleCaptchaLoad}
			/>
	</div>*/}
</React.Fragment>
}
          </form>
		  </div>
		  </Fade>
        </section>
			)}
}

export default Contact
