//import React, { useState, useEffect} from 'react'
import React from 'react'
//import { useState, useEffect} from 'preact/hooks'
//import { h, FunctionalComponent } from 'preact';
//import { Helmet } from 'react-helmet'
import Seo from '../components/SEO'
import metaImage from '../assets/images/rush-promo.jpg'
import Layout from '../components/layout'
import Contact from '../components/Contact'
//import pic07 from '../assets/images/pic07.jpg'
//import pic06 from '../assets/images/pic06.jpg'
import  Animated  from '../components/animated';
//import { useScroll } from '../hooks/useScrolling'
import Shapes from '../components/Shapes'
import loadable from '@loadable/component'
const Lottie1 = loadable(() => import('../components/lottieContact'))

//const scroll = useScroll()
//console.log(scroll);
/*const useScrollDirection = () => {
    const [scrollDirection, setScrollDirection] = useState(null)
    const [prevOffset, setPrevOffset] = useState(0)
    const toggleScrollDirection = () => {
       let scrollY = window.scrollY
       if (scrollY === 0) {
           setScrollDirection(null)
       }
       if (scrollY > prevOffset) {
           setScrollDirection("down")
       } else if (scrollY < prevOffset) {
           setScrollDirection("up")
       }
       setPrevOffset(scrollY)
    }
    useEffect(() => {
        window.addEventListener("scroll", toggleScrollDirection)
        return () => {
            window.removeEventListener("scroll", toggleScrollDirection)
        }
    })
    return scrollDirection
}
*/
//const scrollDirection = useScrollDirection()
//console.log(scrollDirection)

const ContactUs = props => (
  <Layout>
  {/*<Helmet>
      <title>Φόρμα επικοινωνίας</title>
      <meta name="description" content="Συμπληρώστε την φόρμα επικοινωνίας και σύντομα θα επικοινωνήσουμε μαζί σας." />
	  {/*<script src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`} async defer></script>*/}
		  {/*</Helmet>*/}
		<Seo
			title='Φόρμα επικοινωνίας - Rush speedy web apps'
			description='Συμπληρώστε την φόρμα επικοινωνίας και σύντομα θα επικοινωνήσουμε μαζί σας.'
			image={metaImage}
			pathname="/contact"
		//	website
		/>

    <div id="main" className="wrapper style1 ">
	<Shapes />
      <div className="container">
        <header className="major">
          <h1><Animated>
		  Επικοινωνήστε μαζί μας
		  </Animated>
		  </h1>
          <p>
			Καλέστε μας τώρα στο <a href="tel:2103310240">210-3310240</a> ή συμπληρώστε την παρακάτω φόρμα επικοινωνίας και άμεσα θα επικοινωνήσουμε μαζί σας.
          </p>
        </header>
        <div className="row gtr-150">
          <div className="col-8 col-12-medium">
            <section id="content">
<Contact />
            </section>
          </div>
          <div className="col-4 col-12-medium">
            <section id="sidebar">
              <section>
<Lottie1 props={props} />
              </section>
              <hr />

            </section>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactUs
